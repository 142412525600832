import { createSettingsClient } from '@wix/search-settings-client';
export var createAppApi = function () {
    var reportError = function (e) {
        throw e;
    };
    var getSavedSettings = function (_a) {
        var instance = _a.instance, host = _a.host;
        var settingsClient = createSettingsClient({
            instance: instance,
            host: host,
            reportError: reportError,
        });
        return settingsClient.getSaved();
    };
    var saveSettings = function (_a, key, value) {
        var instance = _a.instance, host = _a.host, reportError = _a.reportError;
        var settingsClient = createSettingsClient({
            instance: instance,
            host: host,
            reportError: reportError,
        });
        return settingsClient.set(key, value);
    };
    return {
        getSavedSettings: getSavedSettings,
        saveSettings: saveSettings,
    };
};
