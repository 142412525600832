export var EventType;
(function (EventType) {
    EventType["ComponentAddedToStage"] = "componentAddedToStage";
    EventType["ConnectedComponentPasted"] = "connectedComponentPasted";
})(EventType || (EventType = {}));
export var ComponentType;
(function (ComponentType) {
    ComponentType["SearchBox"] = "wixui.SearchBox";
    ComponentType["TPA"] = "wysiwyg.viewer.components.tpapps.TPASection";
})(ComponentType || (ComponentType = {}));
export var ControllerType;
(function (ControllerType) {
    ControllerType["SearchApp"] = "SearchAppController";
})(ControllerType || (ControllerType = {}));
export var WixCodeRole;
(function (WixCodeRole) {
    WixCodeRole["SearchBox"] = "searchBox";
    WixCodeRole["SearchResults"] = "searchResults";
})(WixCodeRole || (WixCodeRole = {}));
